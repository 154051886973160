<template>
  <div>
    <section class="projects-area portfolio">
      <div class="container">
        <div class="section-heading">
          <h2>Our Works</h2>
          <p>
            We have people of multiple kind in the house. Together we can
            provide high quality work to satisfy you.
          </p>
        </div>

        <div class="row">
          <div class="col-xl-12">
            <div class="project-menu filter-button-group">
              <button
                @click="
                  (design = true),
                    (marketing = true),
                    (bug = true),
                    (custom = true)
                "
                :class="{ active: design && marketing && bug && custom }"
              >
                All
              </button>
              <button
                @click="
                  (design = true),
                    (marketing = false),
                    (bug = false),
                    (custom = false)
                "
                :class="{ active: design }"
              >
                Design
              </button>
              <button
                @click="
                  (design = false),
                    (marketing = true),
                    (bug = false),
                    (custom = false)
                "
                :class="{ active: marketing }"
              >
                Marketing
              </button>
              <button
                @click="
                  (design = false),
                    (marketing = false),
                    (bug = true),
                    (custom = false)
                "
                :class="{ active: bug }"
              >
                Bug Fixing
              </button>
              <button
                @click="
                  (design = false),
                    (marketing = false),
                    (bug = false),
                    (custom = true)
                "
                :class="{ active: custom }"
              >
                Custom Projects
              </button>
            </div>
          </div>
        </div>

        <div class="row projects-holder grid">
          <div
            class="grid-item col-lg-4 col-6 design bug wow animate__animated"
            v-if="design == true || bug == true"
            :class="{ animate__zoomIn: design || bug }"
          >
            <div class="project-box">
              <div class="project-thumbnail">
                <img src="@/assets/projects/work-01.png" alt="Habu" />
              </div>
              <div class="project-text text-center">
                <a href="javascript:void(0)">
                  <h3>View Website</h3>
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    style="enable-background: new 0 0 512 512"
                    xml:space="preserve"
                  >
                    <path
                      style="fill: #2196f3"
                      d="M511.189,259.954c1.649-3.989,0.731-8.579-2.325-11.627l-192-192
        c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l173.803,173.803H10.667
        C4.776,245.213,0,249.989,0,255.88c0,5.891,4.776,10.667,10.667,10.667h464.917L301.803,440.328
        c-4.237,4.093-4.355,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l192-192
        C509.872,262.42,510.655,261.246,511.189,259.954z"
                    />
                    <path
                      d="M309.333,458.546c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571L486.251,255.88
        L301.781,71.432c-4.093-4.237-3.975-10.99,0.262-15.083c4.134-3.992,10.687-3.992,14.82,0l192,192
        c4.164,4.165,4.164,10.917,0,15.083l-192,192C314.865,457.426,312.157,458.546,309.333,458.546z"
                    />
                    <path
                      d="M501.333,266.546H10.667C4.776,266.546,0,261.771,0,255.88c0-5.891,4.776-10.667,10.667-10.667h490.667
        c5.891,0,10.667,4.776,10.667,10.667C512,261.771,507.224,266.546,501.333,266.546z"
                    />
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div
            class="grid-item col-lg-4 col-6 marketing custom wow animate__animated"
            v-if="marketing == true || custom == true"
            :class="{ animate__zoomIn: marketing || custom }"
          >
            <div class="project-box">
              <div class="project-thumbnail">
                <img src="@/assets/projects/work-06.png" alt="Habu" />
              </div>
              <div class="project-text text-center">
                <a href="javascript:void(0)">
                  <h3>View Website</h3>
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    style="enable-background: new 0 0 512 512"
                    xml:space="preserve"
                  >
                    <path
                      style="fill: #2196f3"
                      d="M511.189,259.954c1.649-3.989,0.731-8.579-2.325-11.627l-192-192
        c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l173.803,173.803H10.667
        C4.776,245.213,0,249.989,0,255.88c0,5.891,4.776,10.667,10.667,10.667h464.917L301.803,440.328
        c-4.237,4.093-4.355,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l192-192
        C509.872,262.42,510.655,261.246,511.189,259.954z"
                    />
                    <path
                      d="M309.333,458.546c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571L486.251,255.88
        L301.781,71.432c-4.093-4.237-3.975-10.99,0.262-15.083c4.134-3.992,10.687-3.992,14.82,0l192,192
        c4.164,4.165,4.164,10.917,0,15.083l-192,192C314.865,457.426,312.157,458.546,309.333,458.546z"
                    />
                    <path
                      d="M501.333,266.546H10.667C4.776,266.546,0,261.771,0,255.88c0-5.891,4.776-10.667,10.667-10.667h490.667
        c5.891,0,10.667,4.776,10.667,10.667C512,261.771,507.224,266.546,501.333,266.546z"
                    />
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div
            class="grid-item col-lg-4 col-6 design custom wow animate__animated"
            v-if="design == true || custom == true"
            :class="{ animate__zoomIn: design || custom }"
          >
            <div class="project-box">
              <div class="project-thumbnail">
                <img src="@/assets/projects/work-04.png" alt="Habu" />
              </div>
              <div class="project-text text-center">
                <a href="javascript:void(0)">
                  <h3>View Website</h3>
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    style="enable-background: new 0 0 512 512"
                    xml:space="preserve"
                  >
                    <path
                      style="fill: #2196f3"
                      d="M511.189,259.954c1.649-3.989,0.731-8.579-2.325-11.627l-192-192
        c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l173.803,173.803H10.667
        C4.776,245.213,0,249.989,0,255.88c0,5.891,4.776,10.667,10.667,10.667h464.917L301.803,440.328
        c-4.237,4.093-4.355,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l192-192
        C509.872,262.42,510.655,261.246,511.189,259.954z"
                    />
                    <path
                      d="M309.333,458.546c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571L486.251,255.88
        L301.781,71.432c-4.093-4.237-3.975-10.99,0.262-15.083c4.134-3.992,10.687-3.992,14.82,0l192,192
        c4.164,4.165,4.164,10.917,0,15.083l-192,192C314.865,457.426,312.157,458.546,309.333,458.546z"
                    />
                    <path
                      d="M501.333,266.546H10.667C4.776,266.546,0,261.771,0,255.88c0-5.891,4.776-10.667,10.667-10.667h490.667
        c5.891,0,10.667,4.776,10.667,10.667C512,261.771,507.224,266.546,501.333,266.546z"
                    />
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div
            class="grid-item col-lg-4 col-6 design bug wow animate__animated"
            v-if="design == true || bug == true"
            :class="{ animate__zoomIn: design || bug }"
          >
            <div class="project-box">
              <div class="project-thumbnail">
                <img src="@/assets/projects/work-02.png" alt="Habu" />
              </div>
              <div class="project-text text-center">
                <a href="javascript:void(0)">
                  <h3>View Website</h3>
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    style="enable-background: new 0 0 512 512"
                    xml:space="preserve"
                  >
                    <path
                      style="fill: #2196f3"
                      d="M511.189,259.954c1.649-3.989,0.731-8.579-2.325-11.627l-192-192
        c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l173.803,173.803H10.667
        C4.776,245.213,0,249.989,0,255.88c0,5.891,4.776,10.667,10.667,10.667h464.917L301.803,440.328
        c-4.237,4.093-4.355,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l192-192
        C509.872,262.42,510.655,261.246,511.189,259.954z"
                    />
                    <path
                      d="M309.333,458.546c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571L486.251,255.88
        L301.781,71.432c-4.093-4.237-3.975-10.99,0.262-15.083c4.134-3.992,10.687-3.992,14.82,0l192,192
        c4.164,4.165,4.164,10.917,0,15.083l-192,192C314.865,457.426,312.157,458.546,309.333,458.546z"
                    />
                    <path
                      d="M501.333,266.546H10.667C4.776,266.546,0,261.771,0,255.88c0-5.891,4.776-10.667,10.667-10.667h490.667
        c5.891,0,10.667,4.776,10.667,10.667C512,261.771,507.224,266.546,501.333,266.546z"
                    />
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div
            class="grid-item col-lg-4 col-6 marketing custom wow animate__animated"
            v-if="marketing == true || custom == true"
            :class="{ animate__zoomIn: marketing || custom }"
          >
            <div class="project-box">
              <div class="project-thumbnail">
                <img src="@/assets/projects/work-05.png" alt="Habu" />
              </div>
              <div class="project-text text-center">
                <a href="javascript:void(0)">
                  <h3>View Website</h3>
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    style="enable-background: new 0 0 512 512"
                    xml:space="preserve"
                  >
                    <path
                      style="fill: #2196f3"
                      d="M511.189,259.954c1.649-3.989,0.731-8.579-2.325-11.627l-192-192
        c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l173.803,173.803H10.667
        C4.776,245.213,0,249.989,0,255.88c0,5.891,4.776,10.667,10.667,10.667h464.917L301.803,440.328
        c-4.237,4.093-4.355,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l192-192
        C509.872,262.42,510.655,261.246,511.189,259.954z"
                    />
                    <path
                      d="M309.333,458.546c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571L486.251,255.88
        L301.781,71.432c-4.093-4.237-3.975-10.99,0.262-15.083c4.134-3.992,10.687-3.992,14.82,0l192,192
        c4.164,4.165,4.164,10.917,0,15.083l-192,192C314.865,457.426,312.157,458.546,309.333,458.546z"
                    />
                    <path
                      d="M501.333,266.546H10.667C4.776,266.546,0,261.771,0,255.88c0-5.891,4.776-10.667,10.667-10.667h490.667
        c5.891,0,10.667,4.776,10.667,10.667C512,261.771,507.224,266.546,501.333,266.546z"
                    />
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div
            class="grid-item col-lg-4 col-6 marketing bug custom wow animate__animated"
            v-if="marketing == true || custom == true || bug == true"
            :class="{ animate__zoomIn: marketing || custom || bug }"
          >
            <div class="project-box">
              <div class="project-thumbnail">
                <img src="@/assets/projects/work-03.png" alt="Habu" />
              </div>
              <div class="project-text text-center">
                <a href="javascript:void(0)">
                  <h3>View Website</h3>
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    style="enable-background: new 0 0 512 512"
                    xml:space="preserve"
                  >
                    <path
                      style="fill: #2196f3"
                      d="M511.189,259.954c1.649-3.989,0.731-8.579-2.325-11.627l-192-192
        c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l173.803,173.803H10.667
        C4.776,245.213,0,249.989,0,255.88c0,5.891,4.776,10.667,10.667,10.667h464.917L301.803,440.328
        c-4.237,4.093-4.355,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l192-192
        C509.872,262.42,510.655,261.246,511.189,259.954z"
                    />
                    <path
                      d="M309.333,458.546c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571L486.251,255.88
        L301.781,71.432c-4.093-4.237-3.975-10.99,0.262-15.083c4.134-3.992,10.687-3.992,14.82,0l192,192
        c4.164,4.165,4.164,10.917,0,15.083l-192,192C314.865,457.426,312.157,458.546,309.333,458.546z"
                    />
                    <path
                      d="M501.333,266.546H10.667C4.776,266.546,0,261.771,0,255.88c0-5.891,4.776-10.667,10.667-10.667h490.667
        c5.891,0,10.667,4.776,10.667,10.667C512,261.771,507.224,266.546,501.333,266.546z"
                    />
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div
            class="grid-item col-lg-8 col-6 design wow animate__animated"
            v-if="design == true"
            :class="{ animate__zoomIn: design }"
          >
            <div class="project-box">
              <div class="project-thumbnail">
                <img src="@/assets/projects/work-07.png" alt="Habu" />
              </div>
              <div class="project-text text-center">
                <a href="javascript:void(0)">
                  <h3>View Website</h3>
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    style="enable-background: new 0 0 512 512"
                    xml:space="preserve"
                  >
                    <path
                      style="fill: #2196f3"
                      d="M511.189,259.954c1.649-3.989,0.731-8.579-2.325-11.627l-192-192
        c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l173.803,173.803H10.667
        C4.776,245.213,0,249.989,0,255.88c0,5.891,4.776,10.667,10.667,10.667h464.917L301.803,440.328
        c-4.237,4.093-4.355,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l192-192
        C509.872,262.42,510.655,261.246,511.189,259.954z"
                    />
                    <path
                      d="M309.333,458.546c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571L486.251,255.88
        L301.781,71.432c-4.093-4.237-3.975-10.99,0.262-15.083c4.134-3.992,10.687-3.992,14.82,0l192,192
        c4.164,4.165,4.164,10.917,0,15.083l-192,192C314.865,457.426,312.157,458.546,309.333,458.546z"
                    />
                    <path
                      d="M501.333,266.546H10.667C4.776,266.546,0,261.771,0,255.88c0-5.891,4.776-10.667,10.667-10.667h490.667
        c5.891,0,10.667,4.776,10.667,10.667C512,261.771,507.224,266.546,501.333,266.546z"
                    />
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div
            class="grid-item col-lg-4 col-6 design marketing wow animate__animated"
            v-if="design == true || marketing == true"
            :class="{ animate__zoomIn: marketing || design }"
          >
            <div class="project-box">
              <div class="project-thumbnail">
                <img src="@/assets/projects/work-08.png" alt="Habu" />
              </div>
              <div class="project-text text-center">
                <a href="javascript:void(0)">
                  <h3>View Website</h3>
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    style="enable-background: new 0 0 512 512"
                    xml:space="preserve"
                  >
                    <path
                      style="fill: #2196f3"
                      d="M511.189,259.954c1.649-3.989,0.731-8.579-2.325-11.627l-192-192
        c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l173.803,173.803H10.667
        C4.776,245.213,0,249.989,0,255.88c0,5.891,4.776,10.667,10.667,10.667h464.917L301.803,440.328
        c-4.237,4.093-4.355,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l192-192
        C509.872,262.42,510.655,261.246,511.189,259.954z"
                    />
                    <path
                      d="M309.333,458.546c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571L486.251,255.88
        L301.781,71.432c-4.093-4.237-3.975-10.99,0.262-15.083c4.134-3.992,10.687-3.992,14.82,0l192,192
        c4.164,4.165,4.164,10.917,0,15.083l-192,192C314.865,457.426,312.157,458.546,309.333,458.546z"
                    />
                    <path
                      d="M501.333,266.546H10.667C4.776,266.546,0,261.771,0,255.88c0-5.891,4.776-10.667,10.667-10.667h490.667
        c5.891,0,10.667,4.776,10.667,10.667C512,261.771,507.224,266.546,501.333,266.546z"
                    />
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center">
          <a
            href="javascript:void(0)"
            class="wow animate__animated animate__flipInX large-blue-button"
            >Load More</a
          >
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Projects",
  data() {
    return {
      design: true,
      marketing: true,
      bug: true,
      custom: true,
    };
  },
  computed: {
    allActive: function () {
      return {
        active: this.design && this.marketing && this.bug && this.custom,
      };
    },
  },
};
</script>

<style scoped>
</style>
