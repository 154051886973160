<template>
  <!-- search -->
  <div>
    <div class="col-xl-12 categories-area">
      <h2 class="sidebar-widget-title">Categories</h2>
      <ul>
        <li><a href="javascript:void(0)">Smartphones</a></li>
        <li><a href="javascript:void(0)">Headsets</a></li>
        <li><a href="javascript:void(0)">Computers</a></li>
        <li><a href="javascript:void(0)">Drones</a></li>
        <li><a href="javascript:void(0)">Smartwatch</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "CategoriesSidebar",
};
</script>

<style scoped>
</style>