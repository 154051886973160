<template>
  <div>
    <section class="review-area">
      <div class="container">
        <div class="section-heading text-center mx-auto mb-28">
          <h1>Client Reviews</h1>
          <p>
            We have people of multiple kind in the house. Together we can
            provide high quality work to satisfy you.
          </p>
        </div>
        <div class="rating text-center mx-auto mb-72">
          <p>
            Average rating
            <span>4.9 / 5 <font-awesome-icon :icon="['fas', 'star']" /></span>
          </p>
        </div>
        <VueSlickCarousel v-bind="settings" class="row review-slider">
            <template #prevArrow>
                <button class="slide-prev">
                    <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" />
                </button>
            </template>
          <div>
            <div class="review">
              <div class="review-text">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="31"
                  height="27"
                  viewBox="0 0 31 27"
                >
                  <g data-name="02">
                    <image
                      width="31"
                      height="27"
                      xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAbCAYAAACEP1QvAAAEKElEQVRIiYWXXYhVVRTHf+vcY8kYmZI0kQxkEQkV9VKSFakQlIb4kJGmYVSM1UMPCT0EimFRD75E9VD4NNN3YYIGlVL2kqSGFfgQfVBRgmhZWpMz56xY++x979n77uscGGZ/ra//+rwi+5RpvruA+4AlwGXACWAUeDciU5A8n4tR1gOrBG4AZgBHgduL3GtRSlFGRTklym5RlolyUJQnpYaiZrho/kd/Eu9HipqdRcXxouaFomZWUTNW1Lzq7iouKO1h8s1GOQwsEHgN2Ar8EqwDHheo2hZ3le4t16CMA8eB9QJvAWf92xuBDUAdC1cWAJ8BpwSuBL4n8YpAmTkLStjyCWA78Kwom4GpFFX/WsuiivjsE/gR5bYBzMM3JZlQEWWpF7wK2NkXB9rl51bOcs/oJa/lyhxRa184+v43FwG7BJ4GPmgJigQbfTCm7DSgXCtNBC9D+SND1AGeEXgQZR5wOoPINjEfK9sSNMJ3Pcp2gZuB38zQslM5HqMCR1A+zRBdA3wiymxnEbwB7I1gV4YFl04rskgpTwlOqR9QngN2m6Jm+Uxp8nhthnAWyl6B74ClKGdzUIopqEz6YI0QEbjXo3E/MN6+K8uKeShzgP0Zpi+i/A7cYamSBl7L+gdQXm/HgPSifxzlMcGlXpu38/mdAodQ/ksIZ7h8VO4R+KcFcWS5Xy9HeSi1GtgkcAxlR+aOspxycB4CqoTpcmAS5b0cocTFZQ7Kz5m7daK8D0zkFDfh13nNNCFcJOpqsPYRxu/mo6jQZEkL1rko84GPBilediYZkaYMRv5AWQgczMRBuh4B/gbOSCxgLnAh8PUgxc3yISNOYDSmpYtw2ONLoruyRgO8HGq14IrLhGVCouiQNHDvsEyQhq2l3Z/A8wJfmnD7JpK8tZeWz6sNum4jUVdsrFAsQVnpXw9Jk2ZVgsyvpqQot3gEVBTrJFcBB1BWNMLV+zv2zZgoYxkfX41yVGAR8IVVP2no09g4ibIp6yrYgvJKWU66TZ0Q9g8Hvf1PKEeAxWLClX99plR97XVAdqB8KLDZ1XZpaT0doXRx6t7tsoj2fh/ckGLLJaRaY7nSEVxObvCQnQuFTkvhS20k8gNDPdCAeC+hwgXLzaI9LvjOTRj24WRYlDXAO8F9aa/PBLNjESwPkWqTy1SWKCbs4H0s8BVw9wCE2lC39252tJZqh7XA+cDHfto8MYAo7KVbEeEmlIeBja4cTw85vl50hwkNUExDFNAouhkCC0W5FTgvCM+O0fGZ9CxvRBSecTTVZWY1i9RCemp1QqDlBEaI9c5cxTThf9GMNjYuxwUnYeLPLweuAL7x599a4RG4xE8qiab9PMANqMdsgHzEdzX7NXJG4FHwvT0mMNeYlWtR9gOf+/MDfhB5E+Vtj1wfCB5Bc5U1oo3A6v8BZJW4ayu2muUAAAAASUVORK5CYII="
                    />
                  </g>
                </svg>
                <p>
                  Li lingues differe solmen in li grammat ca, li pronuncia tion
                  e li plu commun vocabules. Omnicos directe al desi lite de un
                  nov lingua fusa.
                </p>
                <svg
                  class="float-right"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="31"
                  height="27"
                  viewBox="0 0 31 27"
                >
                  <g data-name="02">
                    <image
                      data-name="quote copy"
                      width="31"
                      height="27"
                      xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAbCAYAAACEP1QvAAAEJklEQVRIiY2XW4iVVRTHf+s732SIlZlJllFmEYZYiIZZRg9dhi5UL9GDFkp0oZcuYirYQ6IykHSleqmHLhBFkFQQgUZQmglqlBBRjEmKpTZpDjXNmbNi7b2/Ofvb3z7agcP51jp73f573T6RrQrwLPA4UHDqzyHgNmBXdPJ6lI8FzshKOxO0Bf7Gn3kaWFcCDwBPAiuA74GWaE8VBcqDAl8CEwP/bGArylvAewKSMewcFHgCeAx4HmVXWXQYAFYDm1KBupZx/g6BH4E7gI9Q7gR+Ah4S+DdjtNIzGXXcF4ArgVVmfApwsIdAjRf4wygngHMCa5rYVSid1NcEQYl4vwCLymKsbkLyBlNeIcpY9QzuWRvXpTX0isrBcDUdi5xIUU4opS1p7rc7C7yWKQqQNhCL6DbwR+CZI+2y1W4cytIJAjuBvyK6Qy/UuvRmlO1Br/spWz7mdiqYQpjQu0VZCmxzkSsqsXQeuUXAoyh3u4oiRP4/BFNUZkV0IR72HELx8w/AqzFaZelSJYItL5jSK11NK/sCW1Ona0j5/37Fvp5vkXcs8jGBs3pC3kRiosBTwCDKPoHjUQ2fzOH4ufCRtxlAednaZmgerWDQzh5FOZxRKqLj1Gax9qysAd4PZViJDIu6djyaBCKGthl/BuUK4JMk6o4oR13ftvvqChZJnf+MslRw7XV9JspHBF5LkLW2PlK2RhkRXAbORJnujPo7NCPbBPqSPOjDCx8PBuyft4EPRZkTGbAu+A4wIZMPk61ULfKKMejusev1DCtB4JjUvZ4EnOYaRr0STgh8nTh6pmvdzYqZLrA/Nh4nlR2+FjiC8lsiOCVEPxg51HWunhsXi7osT89djvK5N54vrSWiLpKRRPCi4NyfOeNRlDegDAP7k4y3azwXZU9ZjmYFL0XpF1PQ/O8qlG9SZzMlZom8BTiQODgnlOuOqsPFsBr9hnkGfJUIikA/ypuniPphYDHKhRndi0MJD43fOUqfwC1YWfgcXihNpbYCXWMLREapsS5B3Up2l8A9xPftz1qVLAFeFDdY2pTid7hlqMvOL1zpKUOJoH2eA7a4+65XwK3gGtVMYEiUm+xcBp3LUBYI7jwW+QTgRutOYoudhq2mOcnmoSwP6KQda75DRbla/LhNUanod4FXJMx1P1KV08V7erCHkK1aH4Qu9lmmd9vP4TDnc8lXhBZ+PrA2dE/iZaKgKWT0VGAvuAl2X26zFd8Nu00sHUbwEura7Nxqm6kil2rKJIptPVon6sanRdR/EuU2KMYy2+7NwOuh010HfBcHVxm3r/Xb2ZblwO3iXwyMvxF1UNWvoo6SVYo5a83DNhYrp3uBC8RvO5bAv6e+2QJpW4iNPRuJA+GtwxrDJpQNNhYbhpvQ2z43A+Vb4DzgH+DT8GZiLyKNtQzgP84OpYtpBIQgAAAAAElFTkSuQmCC"
                    />
                  </g>
                </svg>
              </div>
              <div class="client-profile">
                <img src="@/assets/clients/client.png" alt="Habu" />
                <div class="client-details">
                  <h2>Nathaneal Down</h2>
                  <p>HD Manager</p>
                  <span class="client-rating"
                    >5 / 5 <font-awesome-icon :icon="['fas', 'star']"
                  /></span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="review">
              <div class="review-text">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="31"
                  height="27"
                  viewBox="0 0 31 27"
                >
                  <g data-name="02">
                    <image
                      width="31"
                      height="27"
                      xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAbCAYAAACEP1QvAAAEKElEQVRIiYWXXYhVVRTHf+vcY8kYmZI0kQxkEQkV9VKSFakQlIb4kJGmYVSM1UMPCT0EimFRD75E9VD4NNN3YYIGlVL2kqSGFfgQfVBRgmhZWpMz56xY++x979n77uscGGZ/ra//+rwi+5RpvruA+4AlwGXACWAUeDciU5A8n4tR1gOrBG4AZgBHgduL3GtRSlFGRTklym5RlolyUJQnpYaiZrho/kd/Eu9HipqdRcXxouaFomZWUTNW1Lzq7iouKO1h8s1GOQwsEHgN2Ar8EqwDHheo2hZ3le4t16CMA8eB9QJvAWf92xuBDUAdC1cWAJ8BpwSuBL4n8YpAmTkLStjyCWA78Kwom4GpFFX/WsuiivjsE/gR5bYBzMM3JZlQEWWpF7wK2NkXB9rl51bOcs/oJa/lyhxRa184+v43FwG7BJ4GPmgJigQbfTCm7DSgXCtNBC9D+SND1AGeEXgQZR5wOoPINjEfK9sSNMJ3Pcp2gZuB38zQslM5HqMCR1A+zRBdA3wiymxnEbwB7I1gV4YFl04rskgpTwlOqR9QngN2m6Jm+Uxp8nhthnAWyl6B74ClKGdzUIopqEz6YI0QEbjXo3E/MN6+K8uKeShzgP0Zpi+i/A7cYamSBl7L+gdQXm/HgPSifxzlMcGlXpu38/mdAodQ/ksIZ7h8VO4R+KcFcWS5Xy9HeSi1GtgkcAxlR+aOspxycB4CqoTpcmAS5b0cocTFZQ7Kz5m7daK8D0zkFDfh13nNNCFcJOpqsPYRxu/mo6jQZEkL1rko84GPBilediYZkaYMRv5AWQgczMRBuh4B/gbOSCxgLnAh8PUgxc3yISNOYDSmpYtw2ONLoruyRgO8HGq14IrLhGVCouiQNHDvsEyQhq2l3Z/A8wJfmnD7JpK8tZeWz6sNum4jUVdsrFAsQVnpXw9Jk2ZVgsyvpqQot3gEVBTrJFcBB1BWNMLV+zv2zZgoYxkfX41yVGAR8IVVP2no09g4ibIp6yrYgvJKWU66TZ0Q9g8Hvf1PKEeAxWLClX99plR97XVAdqB8KLDZ1XZpaT0doXRx6t7tsoj2fh/ckGLLJaRaY7nSEVxObvCQnQuFTkvhS20k8gNDPdCAeC+hwgXLzaI9LvjOTRj24WRYlDXAO8F9aa/PBLNjESwPkWqTy1SWKCbs4H0s8BVw9wCE2lC39252tJZqh7XA+cDHfto8MYAo7KVbEeEmlIeBja4cTw85vl50hwkNUExDFNAouhkCC0W5FTgvCM+O0fGZ9CxvRBSecTTVZWY1i9RCemp1QqDlBEaI9c5cxTThf9GMNjYuxwUnYeLPLweuAL7x599a4RG4xE8qiab9PMANqMdsgHzEdzX7NXJG4FHwvT0mMNeYlWtR9gOf+/MDfhB5E+Vtj1wfCB5Bc5U1oo3A6v8BZJW4ayu2muUAAAAASUVORK5CYII="
                    />
                  </g>
                </svg>
                <p>
                  Li lingues differe solmen in li grammat ca, li pronuncia tion
                  e li plu commun vocabules. Omnicos directe al desi lite de un
                  nov lingua fusa.
                </p>
                <svg
                  class="float-right"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="31"
                  height="27"
                  viewBox="0 0 31 27"
                >
                  <g data-name="02">
                    <image
                      data-name="quote copy"
                      width="31"
                      height="27"
                      xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAbCAYAAACEP1QvAAAEJklEQVRIiY2XW4iVVRTHf+s732SIlZlJllFmEYZYiIZZRg9dhi5UL9GDFkp0oZcuYirYQ6IykHSleqmHLhBFkFQQgUZQmglqlBBRjEmKpTZpDjXNmbNi7b2/Ofvb3z7agcP51jp73f573T6RrQrwLPA4UHDqzyHgNmBXdPJ6lI8FzshKOxO0Bf7Gn3kaWFcCDwBPAiuA74GWaE8VBcqDAl8CEwP/bGArylvAewKSMewcFHgCeAx4HmVXWXQYAFYDm1KBupZx/g6BH4E7gI9Q7gR+Ah4S+DdjtNIzGXXcF4ArgVVmfApwsIdAjRf4wygngHMCa5rYVSid1NcEQYl4vwCLymKsbkLyBlNeIcpY9QzuWRvXpTX0isrBcDUdi5xIUU4opS1p7rc7C7yWKQqQNhCL6DbwR+CZI+2y1W4cytIJAjuBvyK6Qy/UuvRmlO1Br/spWz7mdiqYQpjQu0VZCmxzkSsqsXQeuUXAoyh3u4oiRP4/BFNUZkV0IR72HELx8w/AqzFaZelSJYItL5jSK11NK/sCW1Ona0j5/37Fvp5vkXcs8jGBs3pC3kRiosBTwCDKPoHjUQ2fzOH4ufCRtxlAednaZmgerWDQzh5FOZxRKqLj1Gax9qysAd4PZViJDIu6djyaBCKGthl/BuUK4JMk6o4oR13ftvvqChZJnf+MslRw7XV9JspHBF5LkLW2PlK2RhkRXAbORJnujPo7NCPbBPqSPOjDCx8PBuyft4EPRZkTGbAu+A4wIZMPk61ULfKKMejusev1DCtB4JjUvZ4EnOYaRr0STgh8nTh6pmvdzYqZLrA/Nh4nlR2+FjiC8lsiOCVEPxg51HWunhsXi7osT89djvK5N54vrSWiLpKRRPCi4NyfOeNRlDegDAP7k4y3azwXZU9ZjmYFL0XpF1PQ/O8qlG9SZzMlZom8BTiQODgnlOuOqsPFsBr9hnkGfJUIikA/ypuniPphYDHKhRndi0MJD43fOUqfwC1YWfgcXihNpbYCXWMLREapsS5B3Up2l8A9xPftz1qVLAFeFDdY2pTid7hlqMvOL1zpKUOJoH2eA7a4+65XwK3gGtVMYEiUm+xcBp3LUBYI7jwW+QTgRutOYoudhq2mOcnmoSwP6KQda75DRbla/LhNUanod4FXJMx1P1KV08V7erCHkK1aH4Qu9lmmd9vP4TDnc8lXhBZ+PrA2dE/iZaKgKWT0VGAvuAl2X26zFd8Nu00sHUbwEura7Nxqm6kil2rKJIptPVon6sanRdR/EuU2KMYy2+7NwOuh010HfBcHVxm3r/Xb2ZblwO3iXwyMvxF1UNWvoo6SVYo5a83DNhYrp3uBC8RvO5bAv6e+2QJpW4iNPRuJA+GtwxrDJpQNNhYbhpvQ2z43A+Vb4DzgH+DT8GZiLyKNtQzgP84OpYtpBIQgAAAAAElFTkSuQmCC"
                    />
                  </g>
                </svg>
              </div>
              <div class="client-profile">
                <img src="@/assets/clients/client.png" alt="Habu" />
                <div class="client-details">
                  <h2>Nathaneal Down</h2>
                  <p>HD Manager</p>
                  <span class="client-rating"
                    >4.8 / 5 <font-awesome-icon :icon="['fas', 'star']"
                  /></span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="review">
              <div class="review-text">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="31"
                  height="27"
                  viewBox="0 0 31 27"
                >
                  <g data-name="02">
                    <image
                      width="31"
                      height="27"
                      xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAbCAYAAACEP1QvAAAEKElEQVRIiYWXXYhVVRTHf+vcY8kYmZI0kQxkEQkV9VKSFakQlIb4kJGmYVSM1UMPCT0EimFRD75E9VD4NNN3YYIGlVL2kqSGFfgQfVBRgmhZWpMz56xY++x979n77uscGGZ/ra//+rwi+5RpvruA+4AlwGXACWAUeDciU5A8n4tR1gOrBG4AZgBHgduL3GtRSlFGRTklym5RlolyUJQnpYaiZrho/kd/Eu9HipqdRcXxouaFomZWUTNW1Lzq7iouKO1h8s1GOQwsEHgN2Ar8EqwDHheo2hZ3le4t16CMA8eB9QJvAWf92xuBDUAdC1cWAJ8BpwSuBL4n8YpAmTkLStjyCWA78Kwom4GpFFX/WsuiivjsE/gR5bYBzMM3JZlQEWWpF7wK2NkXB9rl51bOcs/oJa/lyhxRa184+v43FwG7BJ4GPmgJigQbfTCm7DSgXCtNBC9D+SND1AGeEXgQZR5wOoPINjEfK9sSNMJ3Pcp2gZuB38zQslM5HqMCR1A+zRBdA3wiymxnEbwB7I1gV4YFl04rskgpTwlOqR9QngN2m6Jm+Uxp8nhthnAWyl6B74ClKGdzUIopqEz6YI0QEbjXo3E/MN6+K8uKeShzgP0Zpi+i/A7cYamSBl7L+gdQXm/HgPSifxzlMcGlXpu38/mdAodQ/ksIZ7h8VO4R+KcFcWS5Xy9HeSi1GtgkcAxlR+aOspxycB4CqoTpcmAS5b0cocTFZQ7Kz5m7daK8D0zkFDfh13nNNCFcJOpqsPYRxu/mo6jQZEkL1rko84GPBilediYZkaYMRv5AWQgczMRBuh4B/gbOSCxgLnAh8PUgxc3yISNOYDSmpYtw2ONLoruyRgO8HGq14IrLhGVCouiQNHDvsEyQhq2l3Z/A8wJfmnD7JpK8tZeWz6sNum4jUVdsrFAsQVnpXw9Jk2ZVgsyvpqQot3gEVBTrJFcBB1BWNMLV+zv2zZgoYxkfX41yVGAR8IVVP2no09g4ibIp6yrYgvJKWU66TZ0Q9g8Hvf1PKEeAxWLClX99plR97XVAdqB8KLDZ1XZpaT0doXRx6t7tsoj2fh/ckGLLJaRaY7nSEVxObvCQnQuFTkvhS20k8gNDPdCAeC+hwgXLzaI9LvjOTRj24WRYlDXAO8F9aa/PBLNjESwPkWqTy1SWKCbs4H0s8BVw9wCE2lC39252tJZqh7XA+cDHfto8MYAo7KVbEeEmlIeBja4cTw85vl50hwkNUExDFNAouhkCC0W5FTgvCM+O0fGZ9CxvRBSecTTVZWY1i9RCemp1QqDlBEaI9c5cxTThf9GMNjYuxwUnYeLPLweuAL7x599a4RG4xE8qiab9PMANqMdsgHzEdzX7NXJG4FHwvT0mMNeYlWtR9gOf+/MDfhB5E+Vtj1wfCB5Bc5U1oo3A6v8BZJW4ayu2muUAAAAASUVORK5CYII="
                    />
                  </g>
                </svg>
                <p>
                  Li lingues differe solmen in li grammat ca, li pronuncia tion
                  e li plu commun vocabules. Omnicos directe al desi lite de un
                  nov lingua fusa.
                </p>
                <svg
                  class="float-right"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="31"
                  height="27"
                  viewBox="0 0 31 27"
                >
                  <g data-name="02">
                    <image
                      data-name="quote copy"
                      width="31"
                      height="27"
                      xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAbCAYAAACEP1QvAAAEJklEQVRIiY2XW4iVVRTHf+s732SIlZlJllFmEYZYiIZZRg9dhi5UL9GDFkp0oZcuYirYQ6IykHSleqmHLhBFkFQQgUZQmglqlBBRjEmKpTZpDjXNmbNi7b2/Ofvb3z7agcP51jp73f573T6RrQrwLPA4UHDqzyHgNmBXdPJ6lI8FzshKOxO0Bf7Gn3kaWFcCDwBPAiuA74GWaE8VBcqDAl8CEwP/bGArylvAewKSMewcFHgCeAx4HmVXWXQYAFYDm1KBupZx/g6BH4E7gI9Q7gR+Ah4S+DdjtNIzGXXcF4ArgVVmfApwsIdAjRf4wygngHMCa5rYVSid1NcEQYl4vwCLymKsbkLyBlNeIcpY9QzuWRvXpTX0isrBcDUdi5xIUU4opS1p7rc7C7yWKQqQNhCL6DbwR+CZI+2y1W4cytIJAjuBvyK6Qy/UuvRmlO1Br/spWz7mdiqYQpjQu0VZCmxzkSsqsXQeuUXAoyh3u4oiRP4/BFNUZkV0IR72HELx8w/AqzFaZelSJYItL5jSK11NK/sCW1Ona0j5/37Fvp5vkXcs8jGBs3pC3kRiosBTwCDKPoHjUQ2fzOH4ufCRtxlAednaZmgerWDQzh5FOZxRKqLj1Gax9qysAd4PZViJDIu6djyaBCKGthl/BuUK4JMk6o4oR13ftvvqChZJnf+MslRw7XV9JspHBF5LkLW2PlK2RhkRXAbORJnujPo7NCPbBPqSPOjDCx8PBuyft4EPRZkTGbAu+A4wIZMPk61ULfKKMejusev1DCtB4JjUvZ4EnOYaRr0STgh8nTh6pmvdzYqZLrA/Nh4nlR2+FjiC8lsiOCVEPxg51HWunhsXi7osT89djvK5N54vrSWiLpKRRPCi4NyfOeNRlDegDAP7k4y3azwXZU9ZjmYFL0XpF1PQ/O8qlG9SZzMlZom8BTiQODgnlOuOqsPFsBr9hnkGfJUIikA/ypuniPphYDHKhRndi0MJD43fOUqfwC1YWfgcXihNpbYCXWMLREapsS5B3Up2l8A9xPftz1qVLAFeFDdY2pTid7hlqMvOL1zpKUOJoH2eA7a4+65XwK3gGtVMYEiUm+xcBp3LUBYI7jwW+QTgRutOYoudhq2mOcnmoSwP6KQda75DRbla/LhNUanod4FXJMx1P1KV08V7erCHkK1aH4Qu9lmmd9vP4TDnc8lXhBZ+PrA2dE/iZaKgKWT0VGAvuAl2X26zFd8Nu00sHUbwEura7Nxqm6kil2rKJIptPVon6sanRdR/EuU2KMYy2+7NwOuh010HfBcHVxm3r/Xb2ZblwO3iXwyMvxF1UNWvoo6SVYo5a83DNhYrp3uBC8RvO5bAv6e+2QJpW4iNPRuJA+GtwxrDJpQNNhYbhpvQ2z43A+Vb4DzgH+DT8GZiLyKNtQzgP84OpYtpBIQgAAAAAElFTkSuQmCC"
                    />
                  </g>
                </svg>
              </div>
              <div class="client-profile">
                <img src="@/assets/clients/client-02.png" alt="Habu" />
                <div class="client-details">
                  <h2>Nathaneal Down</h2>
                  <p>HD Manager</p>
                  <span class="client-rating"
                    >5 / 5 <font-awesome-icon :icon="['fas', 'star']"
                  /></span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="review">
              <div class="review-text">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="31"
                  height="27"
                  viewBox="0 0 31 27"
                >
                  <g data-name="02">
                    <image
                      width="31"
                      height="27"
                      xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAbCAYAAACEP1QvAAAEKElEQVRIiYWXXYhVVRTHf+vcY8kYmZI0kQxkEQkV9VKSFakQlIb4kJGmYVSM1UMPCT0EimFRD75E9VD4NNN3YYIGlVL2kqSGFfgQfVBRgmhZWpMz56xY++x979n77uscGGZ/ra//+rwi+5RpvruA+4AlwGXACWAUeDciU5A8n4tR1gOrBG4AZgBHgduL3GtRSlFGRTklym5RlolyUJQnpYaiZrho/kd/Eu9HipqdRcXxouaFomZWUTNW1Lzq7iouKO1h8s1GOQwsEHgN2Ar8EqwDHheo2hZ3le4t16CMA8eB9QJvAWf92xuBDUAdC1cWAJ8BpwSuBL4n8YpAmTkLStjyCWA78Kwom4GpFFX/WsuiivjsE/gR5bYBzMM3JZlQEWWpF7wK2NkXB9rl51bOcs/oJa/lyhxRa184+v43FwG7BJ4GPmgJigQbfTCm7DSgXCtNBC9D+SND1AGeEXgQZR5wOoPINjEfK9sSNMJ3Pcp2gZuB38zQslM5HqMCR1A+zRBdA3wiymxnEbwB7I1gV4YFl04rskgpTwlOqR9QngN2m6Jm+Uxp8nhthnAWyl6B74ClKGdzUIopqEz6YI0QEbjXo3E/MN6+K8uKeShzgP0Zpi+i/A7cYamSBl7L+gdQXm/HgPSifxzlMcGlXpu38/mdAodQ/ksIZ7h8VO4R+KcFcWS5Xy9HeSi1GtgkcAxlR+aOspxycB4CqoTpcmAS5b0cocTFZQ7Kz5m7daK8D0zkFDfh13nNNCFcJOpqsPYRxu/mo6jQZEkL1rko84GPBilediYZkaYMRv5AWQgczMRBuh4B/gbOSCxgLnAh8PUgxc3yISNOYDSmpYtw2ONLoruyRgO8HGq14IrLhGVCouiQNHDvsEyQhq2l3Z/A8wJfmnD7JpK8tZeWz6sNum4jUVdsrFAsQVnpXw9Jk2ZVgsyvpqQot3gEVBTrJFcBB1BWNMLV+zv2zZgoYxkfX41yVGAR8IVVP2no09g4ibIp6yrYgvJKWU66TZ0Q9g8Hvf1PKEeAxWLClX99plR97XVAdqB8KLDZ1XZpaT0doXRx6t7tsoj2fh/ckGLLJaRaY7nSEVxObvCQnQuFTkvhS20k8gNDPdCAeC+hwgXLzaI9LvjOTRj24WRYlDXAO8F9aa/PBLNjESwPkWqTy1SWKCbs4H0s8BVw9wCE2lC39252tJZqh7XA+cDHfto8MYAo7KVbEeEmlIeBja4cTw85vl50hwkNUExDFNAouhkCC0W5FTgvCM+O0fGZ9CxvRBSecTTVZWY1i9RCemp1QqDlBEaI9c5cxTThf9GMNjYuxwUnYeLPLweuAL7x599a4RG4xE8qiab9PMANqMdsgHzEdzX7NXJG4FHwvT0mMNeYlWtR9gOf+/MDfhB5E+Vtj1wfCB5Bc5U1oo3A6v8BZJW4ayu2muUAAAAASUVORK5CYII="
                    />
                  </g>
                </svg>
                <p>
                  Li lingues differe solmen in li grammat ca, li pronuncia tion
                  e li plu commun vocabules. Omnicos directe al desi lite de un
                  nov lingua fusa.
                </p>
                <svg
                  class="float-right"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="31"
                  height="27"
                  viewBox="0 0 31 27"
                >
                  <g data-name="02">
                    <image
                      data-name="quote copy"
                      width="31"
                      height="27"
                      xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAbCAYAAACEP1QvAAAEJklEQVRIiY2XW4iVVRTHf+s732SIlZlJllFmEYZYiIZZRg9dhi5UL9GDFkp0oZcuYirYQ6IykHSleqmHLhBFkFQQgUZQmglqlBBRjEmKpTZpDjXNmbNi7b2/Ofvb3z7agcP51jp73f573T6RrQrwLPA4UHDqzyHgNmBXdPJ6lI8FzshKOxO0Bf7Gn3kaWFcCDwBPAiuA74GWaE8VBcqDAl8CEwP/bGArylvAewKSMewcFHgCeAx4HmVXWXQYAFYDm1KBupZx/g6BH4E7gI9Q7gR+Ah4S+DdjtNIzGXXcF4ArgVVmfApwsIdAjRf4wygngHMCa5rYVSid1NcEQYl4vwCLymKsbkLyBlNeIcpY9QzuWRvXpTX0isrBcDUdi5xIUU4opS1p7rc7C7yWKQqQNhCL6DbwR+CZI+2y1W4cytIJAjuBvyK6Qy/UuvRmlO1Br/spWz7mdiqYQpjQu0VZCmxzkSsqsXQeuUXAoyh3u4oiRP4/BFNUZkV0IR72HELx8w/AqzFaZelSJYItL5jSK11NK/sCW1Ona0j5/37Fvp5vkXcs8jGBs3pC3kRiosBTwCDKPoHjUQ2fzOH4ufCRtxlAednaZmgerWDQzh5FOZxRKqLj1Gax9qysAd4PZViJDIu6djyaBCKGthl/BuUK4JMk6o4oR13ftvvqChZJnf+MslRw7XV9JspHBF5LkLW2PlK2RhkRXAbORJnujPo7NCPbBPqSPOjDCx8PBuyft4EPRZkTGbAu+A4wIZMPk61ULfKKMejusev1DCtB4JjUvZ4EnOYaRr0STgh8nTh6pmvdzYqZLrA/Nh4nlR2+FjiC8lsiOCVEPxg51HWunhsXi7osT89djvK5N54vrSWiLpKRRPCi4NyfOeNRlDegDAP7k4y3azwXZU9ZjmYFL0XpF1PQ/O8qlG9SZzMlZom8BTiQODgnlOuOqsPFsBr9hnkGfJUIikA/ypuniPphYDHKhRndi0MJD43fOUqfwC1YWfgcXihNpbYCXWMLREapsS5B3Up2l8A9xPftz1qVLAFeFDdY2pTid7hlqMvOL1zpKUOJoH2eA7a4+65XwK3gGtVMYEiUm+xcBp3LUBYI7jwW+QTgRutOYoudhq2mOcnmoSwP6KQda75DRbla/LhNUanod4FXJMx1P1KV08V7erCHkK1aH4Qu9lmmd9vP4TDnc8lXhBZ+PrA2dE/iZaKgKWT0VGAvuAl2X26zFd8Nu00sHUbwEura7Nxqm6kil2rKJIptPVon6sanRdR/EuU2KMYy2+7NwOuh010HfBcHVxm3r/Xb2ZblwO3iXwyMvxF1UNWvoo6SVYo5a83DNhYrp3uBC8RvO5bAv6e+2QJpW4iNPRuJA+GtwxrDJpQNNhYbhpvQ2z43A+Vb4DzgH+DT8GZiLyKNtQzgP84OpYtpBIQgAAAAAElFTkSuQmCC"
                    />
                  </g>
                </svg>
              </div>
              <div class="client-profile">
                <img src="@/assets/clients/client-02.png" alt="Habu" />
                <div class="client-details">
                  <h2>Nathaneal Down</h2>
                  <p>HD Manager</p>
                  <span class="client-rating"
                    >5 / 5 <font-awesome-icon :icon="['fas', 'star']"
                  /></span>
                </div>
              </div>
            </div>
          </div>
          <template #nextArrow>
                <button class="slide-next">
                    <font-awesome-icon :icon="['fas', 'long-arrow-alt-right']" />
                </button>
            </template>
        </VueSlickCarousel>
      </div>
    </section>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

export default {
  name: "Reviews",
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      settings: {
        autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        infinite: true,
        nextArrow:
          '',
        prevArrow:
          '',
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
    };
  },
};
</script>

<style scoped>

</style>
