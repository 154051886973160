<template>
    <div class="comment-area">
                            <h2>COMMENT: <span>2</span></h2>
                            <div class="comment-box">
                                <div class="comment-author-thumbnail">
                                    <img src="@/assets/team-members/03_team-member-02.png" alt="Habu">
                                </div>
                                <div class="comment-body">
                                    <div class="comment-details">
                                        <a href="javascript:void(0)">
                                            <h3>Jason Statham</h3>
                                        </a>
                                        <a href="javascript:void(0)" class="comment-date">July 06th, 2017</a>
                                    </div>
                                    <div class="main-comment">
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta assumenda
                                            expedita ipsum impedit blanditiis cupiditate quo illo saepe autem magni,
                                            aspernatur exercitationem, consequuntur quas iste!</p>
                                        <button>REPLY</button>
                                    </div>
                                    <div class="comment-box">
                                        <div class="comment-author-thumbnail">
                                            <img src="@/assets/team-members/03_team-member-04.png" alt="Habu">
                                        </div>
                                        <div class="comment-body">
                                            <div class="comment-details">
                                                <a href="javascript:void(0)">
                                                    <h3>Herbie Darbage</h3>
                                                </a>
                                                <a href="javascript:void(0)" class="comment-date">July 06th, 2017</a>
                                            </div>
                                            <div class="main-comment">
                                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta
                                                    assumenda expedita ipsum impedit blanditiis cupiditate quo illo
                                                    saepe autem magni, aspernatur exercitationem, consequuntur quas
                                                    iste!</p>
                                                <button>REPLY</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="comment-box">
                                        <div class="comment-author-thumbnail">
                                            <img src="@/assets/team-members/03_team-member-03.png" alt="Habu">
                                        </div>
                                        <div class="comment-body">
                                            <div class="comment-details">
                                                <a href="javascript:void(0)">
                                                    <h3>Jason Statham</h3>
                                                </a>
                                                <a href="javascript:void(0)" class="comment-date">July 06th, 2017</a>
                                            </div>
                                            <div class="main-comment">
                                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta
                                                    assumenda expedita ipsum impedit blanditiis cupiditate quo illo
                                                    saepe autem magni, aspernatur exercitationem, consequuntur quas
                                                    iste!</p>
                                                <button>REPLY</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="comment-box">
                                <div class="comment-author-thumbnail">
                                    <img src="@/assets/team-members/03_team-member-01.png" alt="Habu">
                                </div>
                                <div class="comment-body">
                                    <div class="comment-details">
                                        <a href="javascript:void(0)">
                                            <h3>Jason Statham</h3>
                                        </a>
                                        <a href="javascript:void(0)" class="comment-date">July 06th, 2017</a>
                                    </div>
                                    <div class="main-comment">
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta assumenda
                                            expedita ipsum impedit blanditiis cupiditate quo illo saepe autem magni,
                                            aspernatur exercitationem, consequuntur quas iste!</p>
                                        <button>REPLY</button>
                                    </div>
                                </div>
                            </div>
                            <!-- Comment Input -->
                            <div class="comment-input">
                                <h2>LEAVE A COMMENT</h2>
                                <div class="comment-form">
                                    <div class="row">
                                        <form action="#">
                                            <div class="col-xl-6">
                                                <input type="text" placeholder="Name">
                                            </div>
                                            <div class="col-xl-6">
                                                <input type="text" placeholder="Email">
                                            </div>
                                            <div class="col-xl-12">
                                                <textarea placeholder="Your Message"></textarea>
                                            </div>
                                            <div class="col-xl-12">
                                                <button class="large-blue-button">POST COMMENT</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
</template>

<script>

export default {
    name: "CommentArea"
}
</script>


<style scoped>
</style>