<template>
  <div>
    <section class="our-team">
        <div class="container">
            <div class="section-heading text-center mx-auto">
                <h2>Our Amazing Team</h2>
                <p>We have people of multiple kind in the house. Together we can provide high quality work to satisfy you.</p>
            </div>
            <div class="team-members wow animate__animated animate__fadeInUp">
                <div class="row">
                    <div class="col-xl-3 col-lg-3 col-sm-6">
                        <div class="member-bio">
                            <img src="@/assets/team-members/03_team-member-01.png" alt="Habu">
                            <h2>Jackson Nash</h2>
                            <h4>Programmer</h4>
                            <div class="member-social-profile"><a class="mx-auto" href="#"><font-awesome-icon :icon="['fab', 'facebook-f']" /></a></div>
                            <div class="member-social-profile"><a class="mx-auto" href="#"><font-awesome-icon :icon="['fab', 'twitter']" /></a></div>
                            <div class="member-social-profile"><a class="mx-auto" href="#"><font-awesome-icon :icon="['fab', 'instagram']" /></a></div>
                            <div class="member-social-profile"><a class="mx-auto" href="#"><font-awesome-icon :icon="['fab', 'youtube']" /></a></div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-sm-6">
                        <div class="member-bio">
                            <img src="@/assets/team-members/03_team-member-02.png" alt="Habu">
                            <h2>John Harper</h2>
                            <h4>Web Developer</h4>
                            <div class="member-social-profile"><a class="mx-auto" href="#"><font-awesome-icon :icon="['fab', 'facebook-f']" /></a></div>
                            <div class="member-social-profile"><a class="mx-auto" href="#"><font-awesome-icon :icon="['fab', 'twitter']" /></a></div>
                            <div class="member-social-profile"><a class="mx-auto" href="#"><font-awesome-icon :icon="['fab', 'instagram']" /></a></div>
                            <div class="member-social-profile"><a class="mx-auto" href="#"><font-awesome-icon :icon="['fab', 'youtube']" /></a></div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-sm-6">
                        <div class="member-bio">
                            <img src="@/assets/team-members/03_team-member-03.png" alt="Habu">
                            <h2>Jason Money</h2>
                            <h4>Digital Marketer</h4>
                            <div class="member-social-profile"><a class="mx-auto" href="#"><font-awesome-icon :icon="['fab', 'facebook-f']" /></a></div>
                            <div class="member-social-profile"><a class="mx-auto" href="#"><font-awesome-icon :icon="['fab', 'twitter']" /></a></div>
                            <div class="member-social-profile"><a class="mx-auto" href="#"><font-awesome-icon :icon="['fab', 'instagram']" /></a></div>
                            <div class="member-social-profile"><a class="mx-auto" href="#"><font-awesome-icon :icon="['fab', 'youtube']" /></a></div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-sm-6">
                        <div class="member-bio">
                            <img src="@/assets/team-members/03_team-member-04.png" alt="Habu">
                            <h2>Lady Cash</h2>
                            <h4>Graphics Designer</h4>
                            <div class="member-social-profile"><a class="mx-auto" href="#"><font-awesome-icon :icon="['fab', 'facebook-f']" /></a></div>
                            <div class="member-social-profile"><a class="mx-auto" href="#"><font-awesome-icon :icon="['fab', 'twitter']" /></a></div>
                            <div class="member-social-profile"><a class="mx-auto" href="#"><font-awesome-icon :icon="['fab', 'instagram']" /></a></div>
                            <div class="member-social-profile"><a class="mx-auto" href="#"><font-awesome-icon :icon="['fab', 'youtube']" /></a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  </div>
</template>

<script>

export default {
  name: "OurTeam"
};


</script>

<style scoped>
</style>
