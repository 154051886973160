<template>
  <!-- search -->
  <div>
    <div class="col-xl-12 popular-tags">
      <h2 class="sidebar-widget-title">Popular Tags</h2>
      <a href="javascript:void(0)">UI/UX</a><a href="javascript:void(0)">WEB</a
      ><a href="javascript:void(0)">DEVELOP</a><a href="javascript:void(0)">AGENCY</a
      ><a href="#">SMM</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "TagcloudSidebar",
};
</script>

<style scoped>
</style>