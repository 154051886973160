<template>
  <div>
    <section class="pricing-area">
        <div class="container">
            <div class="section-heading text-center mx-auto">
                <h2>Frequently Asked Questions</h2>
                <p>We have people of multiple kind in the house. Together we can provide high quality work to satisfy
                    you.</p>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <div class="faq-box">
                        <h2>About Design</h2>
                        <div role="tablist">
                            <div class="card">
                                <div v-b-toggle.collapseOne class="card-header">
                                   <h5 class="mb-0"><a href="javascript:void(0)">How long do you take to finish?</a></h5>
                                </div>

                                <b-collapse id="collapseOne">
                                    <div class="card-body">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
                                        gravida.
                                    </div>
                                </b-collapse>
                            </div>

                            <div class="card">
                                <div v-b-toggle.collapseTwo class="card-header">
                                   <h5 class="mb-0"><a href="javascript:void(0)">How much do you charge for a logo?</a></h5>
                                </div>

                                <b-collapse id="collapseTwo">
                                    <div class="card-body">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
                                        gravida.
                                    </div>
                                </b-collapse>
                            </div>

                            <div class="card">
                                <div v-b-toggle.collapseThree class="card-header">
                                   <h5 class="mb-0"><a href="javascript:void(0)">What is your minimum budget for a business website?</a></h5>
                                </div>

                                <b-collapse id="collapseThree">
                                    <div class="card-body">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
                                        gravida.
                                    </div>
                                </b-collapse>
                            </div>

                            <div class="card">
                                <div v-b-toggle.collapseFour class="card-header">
                                   <h5 class="mb-0"><a href="javascript:void(0)">Can you fix console errors?</a></h5>
                                </div>

                                <b-collapse id="collapseFour">
                                    <div class="card-body">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
                                        gravida.
                                    </div>
                                </b-collapse>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="faq-box">
                        <h2>About Development</h2>
                        <div role="tablist">
                            <div class="card">
                                <div v-b-toggle.collapseFive class="card-header">
                                   <h5 class="mb-0"><a href="javascript:void(0)">How long do you take to finish?</a></h5>
                                </div>

                                <b-collapse id="collapseFive">
                                    <div class="card-body">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
                                        gravida.
                                    </div>
                                </b-collapse>
                            </div>

                            <div class="card">
                                <div v-b-toggle.collapseSix class="card-header">
                                   <h5 class="mb-0"><a href="javascript:void(0)">How much do you charge for a logo?</a></h5>
                                </div>

                                <b-collapse id="collapseSix">
                                    <div class="card-body">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
                                        gravida.
                                    </div>
                                </b-collapse>
                            </div>

                            <div class="card">
                                <div v-b-toggle.collapseSeven class="card-header">
                                   <h5 class="mb-0"><a href="javascript:void(0)">What is your minimum budget for a business website?</a></h5>
                                </div>

                                <b-collapse id="collapseSeven">
                                    <div class="card-body">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
                                        gravida.
                                    </div>
                                </b-collapse>
                            </div>

                            <div class="card">
                                <div v-b-toggle.collapseEight class="card-header">
                                   <h5 class="mb-0"><a href="javascript:void(0)">Can you fix console errors?</a></h5>
                                </div>

                                <b-collapse id="collapseEight">
                                    <div class="card-body">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
                                        gravida.
                                    </div>
                                </b-collapse>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="faq-box">
                        <h2>About Marketing</h2>
                        <div role="tablist">
                            <div class="card">
                                <div v-b-toggle.collapseNine class="card-header">
                                   <h5 class="mb-0"><a href="javascript:void(0)">How long do you take to finish?</a></h5>
                                </div>

                                <b-collapse id="collapseNine">
                                    <div class="card-body">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
                                        gravida.
                                    </div>
                                </b-collapse>
                            </div>

                            <div class="card">
                                <div v-b-toggle.collapseTen class="card-header">
                                   <h5 class="mb-0"><a href="javascript:void(0)">How much do you charge for a logo?</a></h5>
                                </div>

                                <b-collapse id="collapseTen">
                                    <div class="card-body">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
                                        gravida.
                                    </div>
                                </b-collapse>
                            </div>

                            <div class="card">
                                <div v-b-toggle.collapseElvn class="card-header">
                                   <h5 class="mb-0"><a href="javascript:void(0)">What is your minimum budget for a business website?</a></h5>
                                </div>

                                <b-collapse id="collapseElvn">
                                    <div class="card-body">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
                                        gravida.
                                    </div>
                                </b-collapse>
                            </div>

                            <div class="card">
                                <div v-b-toggle.collapseTwlv class="card-header">
                                   <h5 class="mb-0"><a href="javascript:void(0)">Can you fix console errors?</a></h5>
                                </div>

                                <b-collapse id="collapseTwlv">
                                    <div class="card-body">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
                                        gravida.
                                    </div>
                                </b-collapse>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="faq-box">
                        <h2>About Management</h2>
                        <div role="tablist">
                            <div class="card">
                                <div v-b-toggle.collapseThrtn class="card-header">
                                   <h5 class="mb-0"><a href="javascript:void(0)">How long do you take to finish?</a></h5>
                                </div>

                                <b-collapse id="collapseThrtn">
                                    <div class="card-body">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
                                        gravida.
                                    </div>
                                </b-collapse>
                            </div>

                            <div class="card">
                                <div v-b-toggle.collapseFrtn class="card-header">
                                   <h5 class="mb-0"><a href="javascript:void(0)">How much do you charge for a logo?</a></h5>
                                </div>

                                <b-collapse id="collapseFrtn">
                                    <div class="card-body">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
                                        gravida.
                                    </div>
                                </b-collapse>
                            </div>

                            <div class="card">
                                <div v-b-toggle.collapseFftn class="card-header">
                                   <h5 class="mb-0"><a href="javascript:void(0)">What is your minimum budget for a business website?</a></h5>
                                </div>

                                <b-collapse id="collapseFftn">
                                    <div class="card-body">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
                                        gravida.
                                    </div>
                                </b-collapse>
                            </div>

                            <div class="card">
                                <div v-b-toggle.collapseSxtn class="card-header">
                                   <h5 class="mb-0"><a href="javascript:void(0)">Can you fix console errors?</a></h5>
                                </div>

                                <b-collapse id="collapseSxtn">
                                    <div class="card-body">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
                                        gravida.
                                    </div>
                                </b-collapse>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  </div>
</template>

<script>

export default {
  name: "Questions"
};

</script>

<style scoped>
.card-header.not-collapsed {
    background: #1E2A78;
}

.card {
    border: 3px solid #000;
    border-radius: 0;
    margin-bottom: 20px;
}

.card-header {
    border-radius: 0;
    border: none;
    padding: 18px 25px;
    background: #fff;
}

.card-header:focus, .card-header h5:focus {
    outline: none;
}

.card-header a:hover {
    color: #1E2A78;
    text-decoration: none;
}

.card-header a:before {
    content: "\f055";
    font-family: FontAwesome;
    font-size: 25px;
    float: right;
    color: #1E2A78;
    transition: 0.3s;
}

.card-header.not-collapsed a {
    color: #fff;
}

.card-header.not-collapsed a:before {
    color: #fff;
    content: "\f056";
}
</style>
